<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Agent Companies</a></li>
    </ol>
    <h1 class="page-header">AGENT COMPANIES</h1>
    <panel noButton="true" title="AGENT COMPANIES" variant="panel-default">
      <loading v-if="loading"/>
      <template v-else>
        <table
            v-if="companies.length"
            class="table table-striped"
        >
          <thead>
          <th>#</th>
          <th>Name</th>
          <th>Registration No</th>
          <th>Postal Address</th>
          <th>Postal Code</th>
          <th>County</th>
          </thead>
          <tbody>
          <tr
              v-for="(company, i) in companies"
              :key="i"
          >
            <td>{{ pagination.from + i }}</td>
            <td>{{ company.name }}</td>
            <td>{{ company.crp }}</td>
            <td>{{ company.postal_address }}</td>
            <td>{{ company.postal_code }}</td>
            <td>{{ company.county }}</td>
          </tr>
          </tbody>
        </table>
        <div
            v-else
            class="alert alert-warning"
        >
          <p>There are no companies</p>
        </div>
        <pagination
            v-if="pagination.last_page > 1"
            :offset="5"
            :pagination="pagination"
            @paginate="fetch"
        />
      </template>
    </panel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companies: [],
      loading: true,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/agent/companies?page=' + this.pagination.current_page).then(response => {
        this.loading = false;
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.companies = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    }
  },
}
</script>
